import { AppContainer } from 'components/AppContainer';
import { FlightsTable } from 'components/FlightsTable';

export const FlightsPage = () => {
  return (
    <AppContainer title="Flights">
      <FlightsTable />
    </AppContainer>
  );
};
