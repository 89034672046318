import { AppContainer } from 'components/AppContainer';
import { SignupForm } from 'components/SignUpForm';

export const SignupPage = () => {
  return (
    <AppContainer>
      <SignupForm />
    </AppContainer>
  );
};
