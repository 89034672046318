import styled, { css } from 'styled-components';

export const Button = styled.button`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    padding: ${theme.spacings.xsmall} ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius};

    :hover {
      background-color: ${theme.colors.secondary};
    }

    ${disabled &&
    css`
      cursor: auto;
      background-color: ${theme.colors.gray};

      :hover {
        background-color: ${theme.colors.gray};
      }
    `}
  `}
`;
