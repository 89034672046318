import { AppContainer } from 'components/AppContainer';
import { Map } from 'components/Map';

export const MapPage = () => {
  return (
    <AppContainer title="Map">
      <Map />
    </AppContainer>
  );
};
