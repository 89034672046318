import { AppContainer } from 'components/AppContainer';
import { LoginForm } from 'components/LoginForm';

export const LoginPage = () => {
  return (
    <AppContainer>
      <LoginForm />
    </AppContainer>
  );
};
