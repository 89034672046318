import { GetCurrentUserV1 } from '@backend/responses';
import { backendApi } from 'api/backendApi';
import { User } from 'types/user';
import { create } from 'zustand';

interface UserState {
  user: User | undefined;
  updateUser: () => Promise<void>;
}

const updateUser = async (): Promise<User | undefined> => {
  const response = await backendApi.get<GetCurrentUserV1>('/api/v1/user');
  if (response.status !== 200) {
    return undefined;
  }

  return {
    id: response.data.id,
    email: response.data.email,
    balance: response.data.balance,
  };
};

export const userStore = create<UserState>((set) => ({
  user: undefined,
  updateUser: async () => {
    const updatedUser = await updateUser();
    set((state) => ({ ...state, user: updatedUser }));
  },
}));
