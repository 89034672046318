import { GetAircraftResponseV1, PaginatedResponse } from '@backend/responses';
import { backendApi } from 'api/backendApi';
import { Button } from 'components/Button';
import * as FormStyles from 'components/Form';
import { PaginatedTable } from 'components/PaginatedTable';
import { TextInput } from 'components/TextInput';
import { FormEventHandler, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './AircraftTable.styles';

export const AircraftTable = () => {
  const [aircraft, setAircraft] = useState<GetAircraftResponseV1[]>([]);
  const [airport, setAirport] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const loadFlights = async () => {
    const config = {
      params: {
        airport: undefined as string | undefined,
        page,
      },
    };

    if (airport) {
      config.params = { ...config.params, airport };
    }

    const response = await backendApi.get<
      PaginatedResponse<GetAircraftResponseV1>
    >('/api/v1/user/aircraft', config);

    if (response.status !== 200) {
      return;
    }

    setAircraft(response.data.items);
    setTotalPages(response.data._meta.totalPages);
  };

  useEffect(() => {
    loadFlights();
  }, [page]);

  const headers = {
    id: 'Id',
    register: 'Register',
    status: 'Status',
    currentAirport: 'Current Airport',
  };

  const formatAircraftResponse = (aircraft: GetAircraftResponseV1) => {
    return {
      id: aircraft.id,
      register: aircraft.register,
      status: aircraft.status,
      currentAirport: aircraft.currentAirport.icao,
    };
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    return loadFlights();
  };

  const handleRowClick = (aircraftId: number | string) => {
    navigate(`/aircraft/${aircraftId}`);
  };

  return (
    <S.Wrapper>
      <FormStyles.Form onSubmit={handleSubmit}>
        <TextInput name="airport" label="Airport" onInputChange={setAirport} />
        <Button>Search</Button>
      </FormStyles.Form>
      <PaginatedTable
        page={page}
        totalPages={totalPages}
        onRowClick={handleRowClick}
        onNext={() => setPage((p) => p + 1)}
        onPrev={() => setPage((p) => p - 1)}
        itemId="id"
        headers={headers}
        items={aircraft.map(formatAircraftResponse)}
      />
    </S.Wrapper>
  );
};
