import styled, { css } from 'styled-components';
import * as FormStyles from 'components/Form';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    ${FormStyles.Form} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 50rem;
      margin-top: ${theme.spacings.xsmall};
      margin-bottom: ${theme.spacings.medium};

      input {
        font-size: ${theme.spacings.small};
        height: ${theme.spacings.xlarge};
      }

      button {
        padding: 0 ${theme.spacings.xsmall};
        height: ${theme.spacings.xlarge};
      }
    }
  `}
`;
