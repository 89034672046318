import { AircraftTable } from 'components/AircraftTable';
import { AppContainer } from 'components/AppContainer';

export const MyAircraftPage = () => {
  return (
    <AppContainer title="Aircraft">
      <AircraftTable />
    </AppContainer>
  );
};
