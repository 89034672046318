import 'leaflet/dist/leaflet.css';
import 'react-toastify/dist/ReactToastify.css';

import { userStore } from 'contexts/userContext';
import { AircraftDetailPage } from 'pages/AircraftDetail/AircraftDetail';
import { AirportDetail } from 'pages/AirportDetail/AirportDetail';
import { AvailableFlightsPage } from 'pages/AvailableFlights';
import { BuyAircraftDetailPage } from 'pages/BuyAircraftDetail';
import { BuyAircraftListPage } from 'pages/BuyAircraftList';
import { CreateFlightPage } from 'pages/CreateFlight';
import { CurrentFlightPage } from 'pages/CurrentFlight';
import { FlightPage } from 'pages/Flight';
import { FlightsPage } from 'pages/Flights';
import { HomePage } from 'pages/Home';
import { LoginPage } from 'pages/Login';
import { MapPage } from 'pages/Map/Map';
import { MyAircraftPage } from 'pages/MyAircraft';
import { SignupPage } from 'pages/SignUp';
import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from 'styles/global';
import { defaultTheme } from 'styles/theme';
import { useStore } from 'zustand';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/signup',
    element: <SignupPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/flights',
    element: <FlightsPage />,
  },
  {
    path: '/flight/:flightId',
    element: <FlightPage />,
  },
  {
    path: '/my-aircraft',
    element: <MyAircraftPage />,
  },
  {
    path: '/aircraft/:aircraftId',
    element: <AircraftDetailPage />,
  },
  {
    path: '/current-flight',
    element: <CurrentFlightPage />,
  },
  {
    path: '/available-flights',
    element: <AvailableFlightsPage />,
  },
  {
    path: '/buy-aircraft',
    element: <BuyAircraftListPage />,
  },
  {
    path: '/buy-aircraft/:aircraftModelId',
    element: <BuyAircraftDetailPage />,
  },
  {
    path: '/map',
    element: <MapPage />,
  },
  {
    path: '/airport/:airportId',
    element: <AirportDetail />,
  },
  {
    path: '/create-flight',
    element: <CreateFlightPage />,
  },
]);

export const App = () => {
  const { updateUser } = useStore(userStore);
  useEffect(() => {
    updateUser();
  }, []);

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
