import styled, { css } from 'styled-components';
import * as FormStyles from 'components/Form';
import * as ErrorBadgeStyles from 'components/ErrorBadge';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${FormStyles.Form} {
      width: 30%;
    }

    ${ErrorBadgeStyles.Wrapper} {
      margin-bottom: ${theme.spacings.xsmall};
    }
  `}
`;
