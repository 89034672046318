import * as Details from 'components/Detail/Detail.styles';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Details.Wrapper} {
      margin-bottom: ${theme.spacings.xlarge};
      margin-top: ${theme.spacings.medium};
    }
  `}
`;
