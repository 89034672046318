import { getAirportsApi, getFlightTracksApi } from 'api/backendApi';
import { useEffect, useState } from 'react';
import { Marker, TileLayer, useMap, useMapEvent } from 'react-leaflet';
import { toast } from 'react-toastify';
import * as S from './Map.styles';

import { GetAirportsV1Schema } from '@backend/domain/airport/routes/v1/handlers/get-airports';
import { GetTrackV1Response } from '@backend/domain/flight/routes/track/v1/handlers/get';
import { Detail } from 'components/Detail';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { useNavigate } from 'react-router-dom';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [20, 30],
  iconAnchor: [20, 30],
});

const RedIcon = L.icon({
  iconUrl: '/img/marker-icon-red.png',
  shadowUrl: iconShadow,
  iconSize: [20, 30],
  iconAnchor: [20, 30],
});

const createIcon = (rotation: number) => {
  return L.divIcon({
    iconSize: [20, 20],
    className: '',
    html: `<img style="transform: rotate(${
      rotation - 90
    }deg);" src="/img/airplane.svg" />`,
  });
};

type MapProps = {
  setTracksCount?: (count: number) => void;
};

export const Map = ({ setTracksCount }: MapProps) => {
  return (
    <S.StyledMapContainer
      bounds={[
        [55.1788, -54.4042],
        [13.58192, -142.294],
      ]}
      style={{ height: '60rem', width: '100rem' }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <AirportsMaker setTracksCount={setTracksCount} />
    </S.StyledMapContainer>
  );
};

const AirportsMaker = ({ setTracksCount }: MapProps) => {
  const map = useMap();
  const navigate = useNavigate();

  const [airports, setAirports] = useState<GetAirportsV1Schema['items']>([]);
  const [tracks, setTracks] = useState<GetTrackV1Response['items']>([]);

  const fetchAirports = async () => {
    const bounds = map.getBounds();
    const boundsParams = {
      north: bounds.getNorth(),
      south: bounds.getSouth(),
      east: bounds.getEast(),
      west: bounds.getWest(),
    };

    const result = await getAirportsApi(boundsParams);
    if (!result.isOk) {
      toast.error('Unable to fetch airports');
      return;
    }

    setAirports(result.data.items);
  };

  const fetchTracks = async () => {
    const result = await getFlightTracksApi();
    if (!result.isOk) {
      toast.error('Unable to fetch flight tracks');
      return;
    }

    setTracks(result.data.items);
    setTracksCount?.(result.data.items.length);
  };

  useMapEvent('moveend', () => {
    fetchAirports();
  });

  const navigateToAirport = (airportId: number) => {
    navigate(`/airport/${airportId}`);
  };

  useEffect(() => {
    fetchTracks();
    fetchAirports();
    const interval = setInterval(() => {
      fetchTracks();
    }, 15000); // 15 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {airports.map((airport) => (
        <Marker
          key={airport.id}
          position={[airport.lat, airport.lon]}
          icon={airport.hasOwnedAircraft ? RedIcon : DefaultIcon}
        >
          <S.StyledPopup>
            <Detail label="Airport" content={airport.icao} />
            <br />
            <Detail
              label="Fuel Price"
              content={`$${airport.fuelPricePerUSGallon}`}
            />
            <br />
            <Detail
              label="Available Payloads"
              content={airport.availablePayloads}
            />
            <br />
            <Detail
              label="Has owned aircraft"
              content={airport.hasOwnedAircraft ? 'Yes' : 'No'}
            />
            <br />
            <br />
            <S.AirportDetailsLink onClick={() => navigateToAirport(airport.id)}>
              View Details
            </S.AirportDetailsLink>
          </S.StyledPopup>
        </Marker>
      ))}
      {tracks.map((track) => (
        <Marker
          key={track.id}
          position={[track.lat, track.lon]}
          icon={createIcon(track.trueHeading)}
        >
          <S.StyledPopup>
            <Detail label="Aircraft" content={track.modelName} />
            <br />
            <Detail label="Register" content={track.register} />
            <br />
            <Detail label="Departure" content={track.departure} />
            <br />
            <Detail label="Destination" content={track.destination} />
          </S.StyledPopup>
        </Marker>
      ))}
    </>
  );
};
