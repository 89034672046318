import { MapContainer, Popup } from 'react-leaflet';
import styled, { css } from 'styled-components';

export const StyledPopup = styled(Popup)`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.medium};
    strong {
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const AirportDetailsLink = styled.a`
  cursor: pointer;
`;

export const StyledMapContainer = styled(MapContainer)`
  margin-left: auto;
  margin-right: auto;
`;
