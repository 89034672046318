import * as S from './ErrorBadge.styles';

type ErrorBadgeProps = {
  message: string;
};

export const ErrorBadge = ({ message }: ErrorBadgeProps) => {
  return (
    <S.Wrapper>
      <S.Message>{message}</S.Message>
    </S.Wrapper>
  );
};
