import { AppContainer } from 'components/AppContainer';
import { Detail } from 'components/Detail';
import { Map } from 'components/Map';
import { useState } from 'react';
import * as S from './Home.styles';

export const HomePage = () => {
  const [tracksCount, setTracksCount] = useState(0);

  return (
    <AppContainer>
      <S.Wrapper>
        <Detail label="Users Online" content={tracksCount} />
        <Map setTracksCount={setTracksCount} />
      </S.Wrapper>
    </AppContainer>
  );
};
