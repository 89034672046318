import { backendApi } from 'api/backendApi';
import { FormEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as FormStyles from 'components/Form';
import { TextInput } from 'components/TextInput';
import { Button } from 'components/Button';
import * as S from './LoginForm.styles';
import { Title } from 'components/Title';
import { LoginResponseV1 } from '@backend/responses';
import { WebError } from '@backend/types';
import { parseWebError } from 'utils/parseWebError';
import { ErrorBadge } from 'components/ErrorBadge';
import { useStore } from 'zustand';
import { userStore } from 'contexts/userContext';

export const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<WebError>();
  const navigate = useNavigate();
  const { updateUser } = useStore(userStore);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const response = await backendApi.post<LoginResponseV1 | WebError>(
      '/api/v1/user/login',
      { email, password },
    );
    if (response.status !== 200) {
      setError(response.data as WebError);
      return;
    }
    await updateUser();
    navigate('/');
  };

  return (
    <S.Wrapper>
      <FormStyles.Form onSubmit={handleSubmit}>
        <Title>Login</Title>
        {error?.message && <ErrorBadge message={error.message} />}
        <TextInput
          label="Email"
          name="email"
          onInputChange={setEmail}
          error={parseWebError(error, 'email')}
        />
        <TextInput
          label="Password"
          name="password"
          onInputChange={setPassword}
          type="password"
          error={parseWebError(error, 'password')}
        />
        <Button>Login</Button>
      </FormStyles.Form>
    </S.Wrapper>
  );
};
