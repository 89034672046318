import { backendApi } from 'api/backendApi';
import { PaginatedResponse, UserFlightsResponseV1 } from '@backend/responses';
import { useEffect, useState } from 'react';
import { Table } from 'components/Table';
import { useNavigate } from 'react-router-dom';

export const FlightsTable = () => {
  const navigate = useNavigate();
  const [flights, setFlights] = useState<UserFlightsResponseV1[]>([]);

  const loadFlights = async () => {
    const response = await backendApi.get<
      PaginatedResponse<UserFlightsResponseV1>
    >('/api/v1/user/flights');
    if (response.status !== 200) {
      return;
    }

    setFlights(response.data.items);
  };

  useEffect(() => {
    loadFlights();
  }, []);

  const headers = {
    id: 'Id',
    status: 'Status',
    startedAt: 'Started At',
    arrivedAt: 'Arrived At',
    departure: 'Departure',
    destination: 'Destination',
  };

  const handleRowClick = (itemId: number | string) => {
    navigate(`/flight/${itemId}`);
  };

  return (
    <Table
      itemId="id"
      onRowClick={handleRowClick}
      items={flights.map(
        ({
          id,
          status,
          startedAt,
          arrivedAt,
          departure: { icao: departure },
          destination: { icao: destination },
        }) => ({
          id,
          status,
          startedAt: String(startedAt ?? ''),
          arrivedAt: String(arrivedAt ?? ''),
          departure,
          destination,
        }),
      )}
      headers={headers}
    />
  );
};
