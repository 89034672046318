import { SignUpResponseV1 } from '@backend/responses';
import { WebError } from '@backend/types';
import { backendApi } from 'api/backendApi';
import { Button } from 'components/Button';
import { ErrorBadge } from 'components/ErrorBadge';
import * as FormStyles from 'components/Form';
import { TextInput } from 'components/TextInput';
import { Title } from 'components/Title';
import { FormEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseWebError } from 'utils/parseWebError';
import * as S from './SignUpForm.styles';

export const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<WebError>();
  const navigate = useNavigate();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const response = await backendApi.post<SignUpResponseV1 | WebError>(
      '/api/v1/user/signup',
      { email, password },
    );
    if (response.status !== 201) {
      setError(response.data as WebError);
      return;
    }

    navigate('/login');
  };

  return (
    <S.Wrapper>
      <FormStyles.Form onSubmit={handleSubmit}>
        <Title>SignUp</Title>
        {error?.message && <ErrorBadge message={error.message} />}
        <TextInput
          label="Email"
          name="email"
          onInputChange={setEmail}
          error={parseWebError(error, 'email')}
        />
        <TextInput
          label="Password"
          name="password"
          onInputChange={setPassword}
          type="password"
          error={parseWebError(error, 'password')}
        />
        <Button>Create User</Button>
      </FormStyles.Form>
    </S.Wrapper>
  );
};
